const axios = require('axios');
const instance = axios.create({
    baseURL: 'https://careerengine.rexavllp.com/api',
    // baseURL: 'http://localhost:4000/api',
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default instance;

