import React from 'react'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { CareerContext } from '../../context/CareerContext';


import './MainComponent.css'
import Positions from './Positions/Positions';


const MainComponent = () => {
    const [show, setShow] = React.useState(true);

    const {
        isSideOpen,
        setIsSideOpen,
        isSuperAdmin
    } = React.useContext(CareerContext);

    const handleSideBar = () => {
        setIsSideOpen(false);
    }

    return (
        <div>
            <Offcanvas show={isSideOpen} className="border">
                <Offcanvas.Header closeButton onClick={handleSideBar}>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Link to="/position"><h5 className='menus'>Positions</h5></Link>
                    <Link to="/resumes"><h5 className='menus'>Resumes</h5></Link>
                    {isSuperAdmin === 1 ? (
                        <Link to="/admins"><h5 className='menus'>Admins</h5></Link>
                    ) : ""}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default MainComponent