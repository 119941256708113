import React, { useState } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import './Resumes.css';
import { CareerContext } from '../../../context/CareerContext';
import { Link } from 'react-router-dom';
import { MdArrowDownward } from 'react-icons/md';
import { BsFillCaretDownFill } from 'react-icons/bs';
// import { search } from '../../../../../api/routes/careerRoute';

const Resumes = () => {
    const [show, setShow] = useState(false);
    const [showModalResume, setShowModalResume] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [resume, setResume] = useState([]);
    const [positionId, setPositionId] = useState(0);
    const [query, setQuery] = useState('0');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {
        getResumes,
        resumes,
        printResumes,
        deleteResume,
        getPositions,
        positions,
        searchResumes,
    } = React.useContext(CareerContext);

    React.useEffect(() => {
        getResumes(positionId);
    }, [positionId, query == '']);

    React.useEffect(() => {
        getPositions();
    }, [])

    const handleResumeDownload = (id) => {
        printResumes(id);
    }

    const handleDelete = (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this position?');
        if (confirmDelete) {
            deleteResume(id);
        }
    }

    const searchResume = () => {
        searchResumes(query);
        setPositionId(0);
    }

    return (
        <div className='container mt-3'>
            <div className="pos d-flex justify-content-between mb-3 align-items-center">
                <h3 className=''>Resumes</h3>
                <div className="search d-flex">
                    <input type="text" name="search" id="search" className='form-control' placeholder='Search with skill' onChange={(e) => setQuery(e.target.value)} />
                    <div className='btn btn-success' onClick={() => searchResume()}>Search</div>
                </div>
                <div className="sort">
                    {/* <h5>Sort by position</h5> */}
                    <select className="form-control" onChange={(e) => { setPositionId(e.target.value) }} value={positionId}>
                        <option value="0">All positions</option>
                        {positions?.map((position) => (
                            <option key={position.id} value={position.id}>{position.name}</option>
                        ))}
                    </select>
                    <div className="downicon">
                        <BsFillCaretDownFill />
                    </div>
                </div>
            </div>
            <Table striped className='text-center'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {resumes?.map((resume, index) => (
                        <tr key={resume.id}>
                            <td>{index + 1} </td>
                            <td>{resume.gender}. <span className='caps'>{resume.name}</span> <span className='caps'>{resume.surname}</span></td>
                            <td>{resume.email}</td>
                            <td>+91{resume.phone}</td>
                            <td>{new Date(resume.created_at).toJSON().slice(0, 10)}</td>
                            <td>
                                <Link to={`/resumes/${resume.id}`} state={{ resume: resume }}><h6 className='btn btn-success' onClick={() => { setShow(true); setResume(resume) }}>View Profile</h6></Link>
                                <h6 className='btn btn-danger' onClick={() => { handleDelete(resume.id) }}>Delete Resume</h6>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="modal">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resume of {resume.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='login-form-position'>
                            <h3>{resume.name + " " + resume.surname} <span>Says,</span></h3>
                            <div className="details">
                                <h5>My <span>email</span> is</h5>
                                <p>{resume.email}</p>
                            </div>
                            <div className="details">
                                <h5>My <span>phone </span>number is</h5>
                                <p>{resume.phone}</p>
                            </div>
                            <div className="details">
                                <h5>I have an <span>experience</span> of</h5>
                                <p>{resume.experience}</p>
                            </div>
                            <div className="details">
                                <h5>I am <span>from</span></h5>
                                <p>{resume.location}</p>
                            </div>
                            <div className="details">
                                <h5>I am looking forward for the <span>position</span> of</h5>
                                <p>{resume.position}</p>
                            </div>
                            <div className="details">
                                <h5>Visit my <span>profile</span> at</h5>
                                <p><a href={resume.linkedin}>{resume.linkedin}</a></p>
                            </div>
                            <div className="details">
                                <h5>Talking <span>about</span> myself</h5>
                                <p>{resume.about}</p>
                            </div>
                            <div className="details">
                                <h5>I came to know <span>about you</span> from</h5>
                                <p>{resume.how_about_us}</p>
                            </div>
                            <div className="details">
                                <h5>Here is my <span>resume</span>.</h5>
                                <div className="btn btn-primary w-100" onClick={() => handleResumeDownload(resume.id)}>Download My Resume</div>
                                <div className="btn btn-warning mt-3 w-100" onClick={() => { handleClose() }}>View My Resume</div>
                            </div>
                            {/* <div className="resume-view">
                                <object data={`http://localhost:4000/${resume.resume}`} type="application/pdf" width="100%" height="100%">
                                    <p>Alternative text - include a link <a href={`http://localhost:4000/${resume.resume}`}>to the PDF!</a></p>
                                </object>
                            </div> */}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <div className="resumeModal">
                <Modal show={showModalResume} onHide={() => setShowModalResume(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resume of {resume.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='login-form-position'>
                            {/* <h3>{resume.name + " " + resume.surname} <span>Says,</span></h3>
                            <div className="details">
                                <h5>My <span>email</span> is</h5>
                                <p>{resume.email}</p>
                            </div>
                            <div className="details">
                                <h5>My <span>phone </span>number is</h5>
                                <p>{resume.phone}</p>
                            </div>
                            <div className="details">
                                <h5>I have an <span>experience</span> of</h5>
                                <p>{resume.experience}</p>
                            </div>
                            <div className="details">
                                <h5>I am <span>from</span></h5>
                                <p>{resume.location}</p>
                            </div>
                            <div className="details">
                                <h5>I am looking forward for the <span>position</span> of</h5>
                                <p>{resume.position}</p>
                            </div>
                            <div className="details">
                                <h5>Visit my <span>profile</span> at</h5>
                                <p><a href={resume.linkedin}>{resume.linkedin}</a></p>
                            </div>
                            <div className="details">
                                <h5>Talking <span>about</span> myself</h5>
                                <p>{resume.about}</p>
                            </div>
                            <div className="details">
                                <h5>I came to know <span>about you</span> from</h5>
                                <p>{resume.how_about_us}</p>
                            </div>
                            <div className="details">
                                <h5>Here is my <span>resume</span>.</h5>
                                <div className="btn btn-primary w-100" onClick={() => handleResumeDownload(resume.id)}>Download My Resume</div>
                                <div className="btn btn-warning mt-3 w-100" onClick={() => handleResumeDownload(resume.id)}>View My Resume</div>
                            </div> */}
                            <div className="resume-view">
                                <object data={`http://192.168.0.104:4000/${resume.resume}`} type="application/pdf" width="100%" height="100%">
                                    <p>Alternative text - include a link <a href={`http://192.168.0.104:4000/${resume.resume}`}>to the PDF!</a></p>
                                </object>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Resumes