import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom'
import { CareerContext } from '../../context/CareerContext';



const Register = () => {

    const {
        registerUser,
        registerError,
        setRegisterError,
        registerSuccess,
        setRegisterSuccess,
        getOtp,
        otpSuccess,
        otpError,
        passwordError,
        passwordSuccess,
        changePasswordWithOtp
    } = useContext(CareerContext);

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const getOtps = () => {
        getOtp(email);
    }

    const changePassword = () => {
        changePasswordWithOtp(otp, password, confirm)
    }

    const handlePhone = (e) => {
        var theEvent = e;
        // Handle paste
        if (theEvent.type === 'paste') {
            key = theEvent.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    if (localStorage.getItem('isAuthenticated')) {
        return (
            <Navigate to='/position' />
        )
    }

    if (passwordSuccess) {
        return (
            <Navigate to="/" />
        )
    }

    return (
        <div className='login-container'>
            {!otpSuccess ? (
                <Form className='login-form'>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email </Form.Label>
                        <Form.Control type="email" required placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        <Form.Text className="text-danger d-none">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Button variant="primary" className='btn btn-block' onClick={() => { getOtps() }}>
                        Get OTP {otpSuccess}
                    </Button>
                </Form>
            ) : (
                <Form className='login-form'>
                    <div className="error-and-success">
                        <div className={otpError != '' ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                            <h6 className="text-white text-center mb-3">{`Error sending OTP`}</h6>
                        </div>
                        <div className={!otpSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                            <h6 className="text-white text-center mb-3">{`OTP sent successfully.`}</h6>
                        </div>

                    </div>
                    <div className="error-and-success">
                        <div className={passwordError != '' ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                            <h6 className="text-white text-center mb-3">{`${passwordError}`}</h6>
                        </div>
                        <div className={!passwordSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                            <h6 className="text-white text-center mb-3">{`Password Changed Successfully.`}</h6>
                        </div>

                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>OTP </Form.Label>
                        <Form.Control type="password" required placeholder="Enter OTP" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                        <Form.Text className="text-danger d-none">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Password </Form.Label>
                        <Form.Control type="password" required placeholder="Enter Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                        <Form.Text className="text-danger d-none">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Confirm Password </Form.Label>
                        <Form.Control type="password" required placeholder="Confirm Password" value={confirm} onChange={(e) => { setConfirm(e.target.value) }} />
                        <Form.Text className="text-danger d-none">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Button variant="primary" className='btn btn-block' onClick={() => { changePassword() }}>
                        Change Password
                    </Button>
                    <h6 className='mt-3 cpointer'  onClick={getOtps}>Resend OTP</h6>
                </Form>
            )}

        </div>
    )
}

export default Register