import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom'

import Login from './components/Login/Login.jsx'
import ForgotPassword from './components/ForgotPassword/ForgotPassword.jsx'
import SignUp from './components/Register/Register.jsx'
import MainComponent from './components/MainComponent/MainComponent'
import Positions from './components/MainComponent/Positions/Positions'
import Resumes from './components/MainComponent/Resumes/Resumes'
import { CareerContext } from './context/CareerContext'
import Profile from './components/MainComponent/Profile/Profile'
import ResumeView from './components/MainComponent/Resumes/ResumeView'
import Admin from './components/Admins/Admin.jsx'

function App() {

  const {
    isAuthenticated,
    logout,
    setIsSideOpen,
    username
  } = React.useContext(CareerContext);

  const [showDropdown, setShowDropdown] = React.useState(false);

  const handleLogout = () => {
    window.location.href = '/';
    logout();
  }

  const handleSidebar = () => {
    setIsSideOpen(true);
  }

  return (
    <>
      <Router>
        <div className="navigation">
          {isAuthenticated ? <div className='hamburger' onClick={handleSidebar}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAcElEQVRIie3VwQnAIAyF4T+lg7hJV3EUR3GUdhM3SQ9FsIdeCqFF3ncKHnwoCQGR2Vgv3D0DOTivmlkFWIfDBGzBwXsvxuAGHMHBLfh++ZFxnBJXZ0dqZnZvMHcvHq/0vCX4hY++/2qZltai1qLIayeo7YHx8w17/QAAAABJRU5ErkJggg==" />
          </div> : ""}
          <h2>Rexav LLP</h2>
          <div className="navigations">
            {isAuthenticated ?
              <>
                <h5 className='proname' onMouseOver={() => setShowDropdown(!showDropdown)} >{`Hello, ${username}`}</h5>
                <div className={!showDropdown ? 'visit d-none' : 'visit'} onMouseLeave={() => { setShowDropdown(false) }}>
                  <Link to="profile"><h5>View Profile</h5></Link>
                </div>
                <h5 onClick={handleLogout}>Logout</h5>
                <MainComponent />
              </>
              :
              <>
                <Link to='/'><h5>Login</h5></Link>
                <Link to='register'><h5>Register</h5></Link>
              </>
            }
          </div>
        </div>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route exact path="/register" element={<SignUp />} />
            <Route exact path="/forgot" element={<ForgotPassword/>} />
            <Route exact path="/position" element={<Positions />} />
            <Route exact path="/resumes" element={<Resumes />} />
            <Route exact path="/resumes/:id" element={<ResumeView />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/admins" element={<Admin />} />
          </Routes>
        </div>
      </Router>
    </>
  )
}

export default App