import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { CareerContext } from '../../context/CareerContext';

import { MdDelete } from 'react-icons/md';

import './Admin.css';

const Positions = () => {


    const {
        getAllAdmins,
        admins,
        adminError,
        adminSuccess,
        adminUpdateError,
        adminUpdateSuccess,
        deleteAdmin,
        userId,
        verifyAdmin,
        makeSuperAdmin
    } = React.useContext(CareerContext)

    useEffect(() => {
        getAllAdmins()
    }, []);

    const handleDelete = (id) => {
        deleteAdmin(id);
    }

    const handleVerify = (id, isVerified) => {
        verifyAdmin(id, isVerified)
    }

    const handleSuperAdmin = (id, isSuper) => {
        makeSuperAdmin(id, isSuper)
    }

    return (
        <div className='container mt-3'>
            <div className="pos d-flex justify-content-between mb-3 align-items-center">
                <h3 className=''>Admins</h3>
            </div>
            <div className="error-and-success">
                <div className={adminUpdateError != '' && adminUpdateSuccess === false ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                    <h6 className="text-white text-center mb-3">{`Error updating admins`}</h6>
                </div>
                <div className={!adminUpdateSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                    <h6 className="text-white text-center mb-3">{`Successfully updated admin`}</h6>
                </div>
            </div>
            <Table striped className='text-center'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Verified</th>
                        <th>Super Admin</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {admins?.map((admin, index) => (<tr key={admin.id}>
                        {userId == admin.id ? "" :
                            <>
                                <td>{index + 1}</td>
                                <td>{admin.name}</td>
                                <td>
                                    <div className="switch">
                                        <input type="checkbox" id="switch" defaultChecked={admin.verified == 1 ? true : false} onChange={(e) => { handleVerify(admin.id, !admin.verified) }} />
                                        <span className="indicator"></span>
                                    </div>
                                </td>
                                <td>
                                    <div className="switch">
                                        <input type="checkbox" id="switch" defaultChecked={admin.is_superadmin == 1 ? true : false} onChange={(e) => { handleSuperAdmin(admin.id, !admin.is_superadmin) }} />
                                        <span className="indicator"></span>
                                    </div>
                                </td>
                                <td>
                                    <div className="delete-icon" onClick={() => handleDelete(admin.id)}>
                                        <MdDelete color='crimson' style={{ height: "2rem", width: "1.5rem" }} />
                                    </div>
                                </td>
                            </>}
                    </tr>))}
                </tbody>
            </Table>
        </div>
    )
}

export default Positions