import React, { createContext, useState, useContext } from 'react';
import axios from '../instance/AxiosInstance';
import { saveAs } from 'file-saver'

const CareerContext = createContext();
const { Provider } = CareerContext;

const CareerProvider = ({ children }) => {

    const [isSideOpen, setIsSideOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [username, setUsername] = useState(localStorage.getItem('username'));
    const [isSuperAdmin, setIsSuperAdmin] = useState(localStorage.getItem('isSuperAdmin'));
    const [userId, setUserId] = useState(localStorage.getItem('userId'));
    const [profile, setProfile] = useState([]);
    const [passwordError, setPasswordError] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [registerError, setRegisterError] = useState('');
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [otpSuccess, setOtpSuccess] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [adminError, setAdminError] = useState('');
    const [adminSuccess, setAdminSuccess] = useState(false);
    const [adminUpdateError, setAdminUpdateError] = useState('');
    const [adminUpdateSuccess, setAdminUpdateSuccess] = useState(false);

    const [positions, setPositions] = useState([]);
    const [isPositionError, setIsPositionError] = useState('');
    const [isPositionSuccess, setIsPositionSuccess] = useState(false);

    const [resumes, setResumes] = useState([]);

    const login = async (email, password) => {
        setLoginError('')
        const body = JSON.stringify({ email, password });
        try {
            const login = await axios.post('/auth/user/login', body);
            localStorage.setItem('token', login.data.token);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('username', login.data.name);
            localStorage.setItem('isSuperAdmin', login.data.isSuperAdmin);
            localStorage.setItem('userId', login.data.id)
            setIsAuthenticated(true);
            setToken(login.data.token);
            setUsername(login.data.name);
            setIsSuperAdmin(login.data.isSuperAdmin);
            setUserId(login.data.id);
            setLoginError('');
        } catch (error) {
            setLoginError(error.response.data.message);
            console.log(error);
        }
    }

    const logout = async () => {
        try {
            setToken(null);
            setUsername('');
            setIsSuperAdmin(0);
            localStorage.removeItem('token');
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('username');
            localStorage.removeItem('isSuperAdmin');
        } catch (error) {
            console.log(error);
        }
    }

    const getPositions = async () => {
        try {
            const positions = await axios.get('/careers/byadmin', {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setPositions(positions.data.positions)
        } catch (error) {
            console.log(error);
        }
    }

    const savePositions = async (name, description, requirements, responsibilites, experience, lastDate, ogDescription, ogTitle) => {
        const body = JSON.stringify({ name, description, requirements, responsibilites, experience, lastDate, ogDescription, ogTitle });
        setIsPositionError('');
        setIsPositionSuccess(false);
        try {
            const savePositions = await axios.post('/careers', body, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setIsPositionSuccess(true)
        } catch (error) {
            setIsPositionError(error.response.data.message);
        }
    }

    const updatePositions = async (name, description, requirements, responsibilites, experience, is_active, lastDate, ogDescription, ogTitle, id) => {
        const body = JSON.stringify({ name, description, requirements, responsibilites, experience, is_active, lastDate, ogDescription, ogTitle });
        setIsPositionError('');
        setIsPositionSuccess(false);
        console.log(body);
        try {
            const updatePositions = await axios.put('/careers/edit/' + id, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setIsPositionSuccess(true)
        } catch (error) {
            setIsPositionError(error.response.data.message);
        }
    }

    const getResumes = async (id) => {
        try {
            const getResumes = await axios.get('/careers/resumes/' + id, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setResumes(getResumes.data.resumesDetails)
        } catch (error) {
            console.error(error)
        }
    }

    const searchResumes = async (query) => {
        try {
            const getResumes = await axios.get('/careers/search/resumes/' + query, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setResumes(getResumes.data.resumesDetails)
        } catch (error) {
            console.error(error)
        }
    }

    // Download resumes
    async function printResumes(id) {
        const { data } = await getResumesPdf(id)
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, `resumes/${id}.pdf`)
    }

    async function getResumesPdf(id) {
        return axios.get('/careers/download/resume/' + id, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': `Bearer ${token}`
            },
            responseType: 'arraybuffer'
        })
    }

    // Register user

    const registerUser = async (name, email, phone, password) => {
        setRegisterError('');
        setRegisterSuccess(false);
        const body = JSON.stringify({ name, email, phone, password });
        try {
            const registerUser = await axios.post('/auth/user', body);
            console.log(registerUser);
            setRegisterError('');
            setRegisterSuccess(true);
        } catch (error) {
            console.log(error);
            setRegisterError(error.response.data.message);
        }
    }

    // 
    const getUser = async () => {
        try {
            const user = await axios.get('/auth/user', {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            }
            );
            setProfile(user.data.user)
            console.log(user);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangePassword = async (oldPassword, newPassword1, newPassword2) => {
        setPasswordError('');
        setPasswordSuccess(false);
        const body = JSON.stringify({ oldPassword, newPassword1, newPassword2 });
        try {
            const handlePasswordChange = await axios.put('/auth/user/changepassword', body, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setPasswordError('');
            setPasswordSuccess(true);
            console.log(handlePasswordChange);
        } catch (error) {
            setPasswordError(error.response.data.message);
            console.log(error);
        }
    }

    const deletePosition = async (id) => {
        console.log(id);
        try {
            const handleDeletePosition = await axios.delete('/careers/delete/' + id, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            // setPasswordError('');
            // setPasswordSuccess(true);
            getPositions();
        } catch (error) {
            // setPasswordError(error.response.data.message);
            console.log(error);
        }
    }

    const deleteResume = async (id) => {
        try {
            const deleteResume = await axios.delete('/careers/delete/resume/' + id, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            // setPasswordError('');
            // setPasswordSuccess(true);
            getResumes(0);
        } catch (error) {
            // setPasswordError(error.response.data.message);
            console.log(error);
        }
    }

    // Get OTP for changing password;

    const getOtp = async (email) => {
        setOtpError('');
        setOtpSuccess(false);
        const body = JSON.stringify({ email });
        try {
            const handleOtp = await axios.put('/auth/user/forgotpassword', body, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setOtpError('');
            setOtpSuccess(true);
            console.log(handleOtp);
        } catch (error) {
            setOtpError(error.response.data.message);
            console.log(error);
        }
    }

    // Change Password

    const changePasswordWithOtp = async (otp, newPassword1, newPassword2) => {
        setOtpError('');
        setPasswordError('');
        setPasswordSuccess(false);
        const body = JSON.stringify({ otp, newPassword1, newPassword2 });
        try {
            const handleOtp = await axios.put('/auth/user/changepasswordotp', body, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setOtpError('');
            setPasswordError('');
            setPasswordSuccess(true)
        } catch (error) {
            setPasswordError(error.response.data.message);
            console.log(error);
        }
    }

    // Get all admins

    const getAllAdmins = async () => {
        setAdminError('');
        setAdminSuccess(false)

        try {
            const getAdmins = await axios.get('/admin', {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            setAdmins(getAdmins.data.admins)
            setAdminError('');
            setAdminSuccess(false)

        } catch (error) {
            setPasswordError(error.response.data.message);
            console.log(error);
        }
    }

    // Delete admins

    const deleteAdmin = async (id) => {
        setAdminUpdateError('');
        setAdminSuccess(false)

        try {
            const deleteAdmin = await axios.delete('/admin/delete/' + id, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            getAllAdmins();
            setAdminUpdateError('');
            setAdminUpdateSuccess(false)

        } catch (error) {
            setPasswordError(error.response.data.message);
            console.log(error);
        }
    }

    // Verify admin 

    const verifyAdmin = async (id, isVerified) => {
        setAdminUpdateError('');
        setAdminSuccess(false)

        const body = JSON.stringify({ isVerified })

        try {
            const verifyAdmin = await axios.put('/admin/verify/' + id, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            getAllAdmins();
            setAdminUpdateError('');
            setAdminUpdateSuccess(false)

        } catch (error) {
            setPasswordError(error.response.data.message);
            getAllAdmins();
            console.log(error);
        }
    }

    // Verify admin 

    const makeSuperAdmin = async (id, isSuper) => {
        setAdminUpdateError('');
        setAdminSuccess(false)

        const body = JSON.stringify({ isSuper })

        try {
            const verifyAdmin = await axios.put('/admin/makeadmin/' + id, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
            })
            getAllAdmins();
            setAdminUpdateError('');
            setAdminUpdateSuccess(false)

        } catch (error) {
            setPasswordError(error.response.data.message);
            getAllAdmins();
            console.log(error);
        }
    }

    return (
        <Provider
            value={{
                isSideOpen,
                setIsSideOpen,
                login,
                isAuthenticated,
                username,
                logout,
                getPositions,
                positions,
                savePositions,
                isPositionError,
                setIsPositionError,
                isPositionSuccess,
                setIsPositionSuccess,
                updatePositions,
                getResumes,
                resumes,
                printResumes,
                registerUser,
                getUser,
                profile,
                handleChangePassword,
                passwordError,
                setPasswordError,
                passwordSuccess,
                setPasswordSuccess,
                registerError,
                setRegisterError,
                registerSuccess,
                setRegisterSuccess,
                loginError,
                setLoginError,
                deletePosition,
                deleteResume,
                searchResumes,
                getOtp,
                otpError,
                otpSuccess,
                changePasswordWithOtp,
                setPasswordSuccess,
                isSuperAdmin,
                getAllAdmins,
                admins,
                adminError,
                adminSuccess,
                deleteAdmin,
                adminUpdateError,
                adminUpdateSuccess,
                userId,
                verifyAdmin,
                makeSuperAdmin
            }}>
            {children}
        </Provider>
    );
};

export { CareerContext, CareerProvider };
