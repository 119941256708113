import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom'
import { CareerContext } from '../../context/CareerContext';



const Register = () => {

    const {
        registerUser,
        registerError,
        setRegisterError,
        registerSuccess,
        setRegisterSuccess,
    } = useContext(CareerContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');

    const registerUsers = () => {
        registerUser(name, email, phone, password);
    }

    const handlePhone = (e) => {
        var theEvent = e;
        // Handle paste
        if (theEvent.type === 'paste') {
            key = theEvent.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    if (localStorage.getItem('isAuthenticated')) {
        return (
            <Navigate to='/position' />
        )
    }

    if (registerSuccess) {
        return (
            <Navigate to="/" />
        )
    }

    return (
        <div className='login-container'>
            <Form className='login-form'>
                <div className="error-and-success">
                    <div className={registerError != '' ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                        <h6 className="text-white text-center mb-3">{`Error creating account: `}{registerError}</h6>
                    </div>
                    <div className={!registerSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                        <h6 className="text-white text-center mb-3">{`Successfully created account.`}</h6>
                    </div>
                </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Name </Form.Label>
                    <Form.Control type="text" required placeholder="Enter name" value={name} onChange={(e) => { setName(e.target.value) }} />
                    <Form.Text className="text-danger d-none">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email </Form.Label>
                    <Form.Control type="email" required placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    <Form.Text className="text-danger d-none">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone </Form.Label>
                    <Form.Control type="tel" required placeholder="Enter phone" value={phone} onKeyPress={(e) => { handlePhone(e) }} onPaste={(e) => { handlePhone(e) }} onChange={(e) => { setPhone(e.target.value) }} />
                    <Form.Text className="text-danger d-none">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" required placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    <Form.Text className="text-danger d-none">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Button variant="primary" className='btn btn-block' onClick={() => { registerUsers() }}>
                    Register
                </Button>
            </Form>
        </div>
    )
}

export default Register