import React, { useState } from 'react'
import { CareerContext } from '../../../context/CareerContext'
import './Profile.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Profile = () => {
    const [show, setShow] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {
        getUser,
        profile,
        handleChangePassword,
        passwordError,
        setPasswordError,
        passwordSuccess,
        setPasswordSuccess,
    } = React.useContext(CareerContext)

    React.useEffect(() => {
        getUser();
    }, []);

    const handlePassword = () => {
        handleChangePassword(oldPassword, newPassword1, newPassword2);
    }

    return (
        <div>
            <div className="profile-wrapper container">
                <div className="shadow text-center p-3 rounded">
                    <div className={passwordError != '' ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                        <h6 className="text-white text-center mb-3">{`Error updating password: `}{passwordError}</h6>
                    </div>
                    <div className={!passwordSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                        <h6 className="text-white text-center mb-3">{`Successfully updated password.`}</h6>
                    </div>
                    <h1 className='m-3'>{profile[0]?.name}</h1>
                    <h4 className='m-3'>{profile[0]?.email}</h4>
                    <div className="btn btn-danger m-3" onClick={handleShow}>Change Password</div>
                </div>
            </div>
            <div className="modal">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className='login-form-position'>
                            {/* <div className={isPositionError != '' && isPositionSuccess === true ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                                <h6 className="text-white text-center mb-3">{`Error ${isSave ? 'saving' : 'updating'} position`}{isPositionError}</h6>
                            </div>
                            <div className={!isPositionSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                                <h6 className="text-white text-center mb-3">{`Successfully ${isSave ? 'saved' : 'updated'} position`}</h6>
                            </div> */}
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter old password" defaultValue={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter new password" defaultValue={newPassword1} onChange={(e) => setNewPassword1(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm password" defaultValue={newPassword2} onChange={(e) => setNewPassword2(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Button variant="primary" className='btn btn-block' onClick={() => { handlePassword(); handleClose(); }}>
                                Change Position
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Profile