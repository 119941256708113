import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { CareerContext } from '../../context/CareerContext';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom'

import './Login.css'

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const {
        login,
        isAuthenticated,
        loginError,
        setLoginError,
    } = React.useContext(CareerContext);


    const handleLogin = () => {
        if (!email || !password) {
            setLoginError('Please enter all required fields');
        }
        else {
            login(email, password);
        }
    }

    if (localStorage.getItem('isAuthenticated')) {
        return (
            <Navigate to='/position' />
        )
    }

    return (
        <div className='login-container'>
            <Form className='login-form'>
                <div className={loginError != '' ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                    <h6 className="text-white text-center mb-3">{`Error logging in: `}{loginError}</h6>
                </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                    <Form.Text className="text-danger d-none">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    <Form.Text className="text-danger d-none">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <div className='btn btn-block btn-primary' onClick={handleLogin}>
                    Login
                </div>
                <Link to="/forgot"><h6 className='mt-3 cpointer'>Forgot password?</h6></Link>
            </Form>
        </div>
    )
}

export default Login