import React, { useState } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './Positions.css';
import { CareerContext } from '../../../context/CareerContext';

const Positions = () => {
    const [show, setShow] = useState(false);
    const [isSave, setIsSave] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [requirements, setRequirements] = useState('');
    const [responsibilites, setResponsibilites] = useState('');
    const [experience, setExperience] = useState('');
    const [id, setId] = useState('');
    const [ogDescription, setOgDescription] = useState('');
    const [ogTitle, setOgTitle] = useState('');
    const [lastDate, setLastDate] = useState('');
    const [isDate, setIsDate] = useState(false);
    const handleOldValue = (position) => {
        setName(position.name);
        setContent(position.content);
        setIsActive(position.is_active === 1 ? true : false);
        setId(position.id);
        setRequirements(position.requierment);
        setResponsibilites(position.responsiblity);
        setExperience(position.experience);
        setOgDescription(position.og_description);
        setOgTitle(position.og_title);
        setLastDate(position.last_date);
        setIsDate(false);
    }

    const {
        getPositions,
        positions,
        savePositions,
        isPositionError,
        setIsPositionError,
        isPositionSuccess,
        setIsPositionSuccess,
        updatePositions,
        deletePosition,
    } = React.useContext(CareerContext)

    React.useEffect(() => {
        getPositions();

        // To hide the success alert after 4 seconds
        setTimeout(function () {
            setIsPositionSuccess(false);
        }, 4000);
    }, [isPositionSuccess]);

    React.useEffect(() => {
        // Clear all fields when modal close
        if (show === false) {
            setName('');
            setContent('');
            setIsActive(false);
            setId('');
            setExperience('');
            setRequirements('');
            setResponsibilites('');
            setLastDate('');
            setOgDescription('');
            setOgTitle('');
        }
    }, [isSave, show])

    const handleSave = () => {
        savePositions(name, content, requirements, responsibilites, experience, lastDate, ogDescription, ogTitle);
    }

    const handleUpdate = () => {
        updatePositions(name, content, requirements, responsibilites, experience, isActive, lastDate, ogDescription, ogTitle, id);
    }

    const handleDelete = (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this position?');
        if (confirmDelete) {
            deletePosition(id);
        }
    }

    const handleDate = (lastDate) => {
        console.log(lastDate);
        if (lastDate === '' || lastDate === null) {
            return '';
        }
        var mydate = new Date(lastDate);
        var dd = mydate.getDate();
        var mm = mydate.getMonth() + 1; //January is 0!

        var yyyy = mydate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var dateformat = dd + '/' + mm + '/' + yyyy;
        return dateformat;
    }

    return (
        <div className='container mt-3'>
            <div className="pos d-flex justify-content-between mb-3 align-items-center">
                <h3 className=''>Positions</h3>
                <div className="btn btn-primary" onClick={() => { setShow(true); setIsSave(true) }}>Add</div>
            </div>
            <div className="error-and-success">
                <div className={isPositionError != '' && isPositionSuccess === false ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                    <h6 className="text-white text-center mb-3">{`Error ${isSave ? 'saving' : 'updating'} position: `}{isPositionError}</h6>
                </div>
                <div className={!isPositionSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                    <h6 className="text-white text-center mb-3">{`Successfully ${isSave ? 'saved' : 'updated'} position`}</h6>
                </div>
            </div>
            <Table striped className='text-center'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Active</th>
                        <th>Count</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {positions?.map((position, index) => (
                        !position.is_open ?
                            <>
                                <tr key={position.id}>
                                    <td>{index + 1}</td>
                                    <td>{position.name}</td>
                                    <td>{position.is_active == 1 ? '🟢' : '🔴'}</td>
                                    <td>{position.resume_count}</td>
                                    <td>
                                        <h6 className='btn btn-success' onClick={() => { setShow(true); setIsSave(false); handleOldValue(position) }}>Edit</h6>
                                        <h6 className='btn btn-danger' onClick={() => { handleDelete(position.id) }}>Delete</h6>
                                    </td>
                                </tr>
                            </>
                            : ""
                    ))}
                </tbody>
            </Table>
            <div className="modal">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isSave ? 'Create new position' : 'Edit ' + name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className='login-form-position'>
                            <div className={isPositionError != '' && isPositionSuccess === true ? "errors bg-danger p-2 rounded" : "errors bg-danger p-2 rounded d-none"}>
                                <h6 className="text-white text-center mb-3">{`Error ${isSave ? 'saving' : 'updating'} position`}{isPositionError}</h6>
                            </div>
                            <div className={!isPositionSuccess === true ? "bg-success p-2 rounded d-none" : "bg-success p-2 rounded"}>
                                <h6 className="text-white text-center mb-3">{`Successfully ${isSave ? 'saved' : 'updated'} position`}</h6>
                            </div>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Position Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Description</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={content}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setContent(data);
                                    }}
                                    config={{
                                        toolbar: ['bold', 'italic', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                    }}
                                />
                                {/* <Form.Control as="textarea" type="text" placeholder="Enter description" defaultValue={content} onChange={(e) => setContent(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text> */}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Responsibilites</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={responsibilites}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setResponsibilites(data);
                                    }}
                                    config={{
                                        toolbar: ['bold', 'italic', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Requirments</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={requirements}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setRequirements(data);
                                    }}
                                    config={{
                                        toolbar: ['bold', 'italic', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Experience</Form.Label>
                                <Form.Control type="text" placeholder="Enter experienece" defaultValue={experience} onChange={(e) => setExperience(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Last Date of Application</Form.Label>
                                <Form.Control type={isDate ? "date" : "text"} onFocus
                                    ={() => setIsDate(true)} placeholder="Enter last date of applications" defaultValue={handleDate(lastDate)} onChange={(e) => setLastDate(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>OG Title</Form.Label>
                                <Form.Control type="text" placeholder="Enter OG Title" defaultValue={ogTitle} onChange={(e) => setOgTitle(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>OG Description</Form.Label>
                                <Form.Control type="text" placeholder="Enter OG Description" defaultValue={ogDescription} onChange={(e) => setOgDescription(e.target.value)} />
                                <Form.Text className="text-danger d-none">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            {isSave ?
                                ""
                                : <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Position Active</Form.Label>
                                    <Form.Select defaultValue={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                        <option value={true}>Active</option>
                                        <option value={false}>Not Active</option>
                                    </Form.Select>
                                </Form.Group>
                            }
                            {isSave ?
                                (
                                    <Button variant="primary" className='btn btn-block' onClick={() => { handleSave(); handleClose(); }}>
                                        Save Position
                                    </Button>
                                )
                                :
                                (
                                    <Button variant="primary" className='btn btn-block' onClick={() => { handleUpdate(); handleClose(); }}>
                                        Update Position
                                    </Button>
                                )}
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Positions